"use client";

import { useTranslations } from "next-intl";
import { useCallback } from "react";
import { useTransition } from "react";
import { toast } from "react-toastify";

import { setCookie } from "@/app/_actions/setCookie";
import { setRequestLocale } from "@/app/_actions/setRequestLocale";
import { usePathname, useRouter } from "@/i18n";
import { useGTMContext } from "@/providers";
import { generateUUID } from "@/utils/crypto";
import { COOKIES } from "~/.constants/cookies";
import { GE } from "~/.constants/gtmEvents";

import { ToastIcon } from "../ToastIcon";
import { ToastMessage } from "../ToastMessage";
import { ToastCloseFunction } from "./types";
import { COOKIE_OPTIONS } from "./vars";

export const useChangeLang = (browserLocale: string, currLocale: string) => {
  const tToast = useTranslations("HomePage.toast");

  const { sendGTM } = useGTMContext();

  const router = useRouter();
  const pathname = usePathname();

  const [isPending, startTransition] = useTransition();

  const handleError = useCallback(
    (error: Error) => {
      toast.error(
        <ToastMessage title={tToast("error")} text={error.message} />,
        {
          icon: ({ type }) => ToastIcon(type),
          toastId: generateUUID(),
        },
      );
    },
    [tToast],
  );

  const setHideDetectCookie = useCallback(async () => {
    await setCookie(COOKIES.HIDE_DETECT_TOAST, "1", COOKIE_OPTIONS.hideDetect);
  }, []);

  const changeLocale = useCallback(async () => {
    await Promise.all([
      setCookie(COOKIES.NEXT_LOCALE, browserLocale, COOKIE_OPTIONS.locale),
      setRequestLocale(browserLocale),
      setHideDetectCookie(),
    ]);

    return router.replace(pathname, { locale: browserLocale });
  }, [browserLocale, pathname, router, setHideDetectCookie]);

  const onAccept = useCallback(
    async (closeToast: ToastCloseFunction) => {
      try {
        sendGTM({
          event: GE.USER_CHOOSE_BROWSER_LANG,
          ecommerce: {
            change_language_from: currLocale,
            change_language_to: browserLocale,
          },
        });

        startTransition(async () => {
          await changeLocale();
          closeToast(true);
        });
      } catch (err) {
        handleError(err as Error);
      }
    },
    [browserLocale, currLocale, changeLocale, sendGTM, handleError],
  );

  const onCancel = useCallback(
    async (closeToast: ToastCloseFunction) => {
      try {
        sendGTM({ event: GE.USER_CANCEL_BROWSER_LANG });
        closeToast(true);
        await setHideDetectCookie();
      } catch (err) {
        handleError(err as Error);
      }
    },
    [sendGTM, setHideDetectCookie, handleError],
  );

  return { onAccept, onCancel, isPending };
};
