"use client";

import { memo, useEffect, useCallback } from "react";
import { toast } from "react-toastify";

import type { OfferChangeLangProps } from "./types";

import { ToastContent } from "./ToastContent";
import { useChangeLang } from "./useChangeLang";
import { TOAST_OPTIONS } from "./vars";

export const OfferChangeLang = memo(
  ({ browserLocale, currLocale, supportedLang }: OfferChangeLangProps) => {
    const { onAccept, onCancel, isPending } = useChangeLang(
      browserLocale,
      currLocale,
    );

    const showToast = useCallback(() => {
      toast.info(
        ({ closeToast }) => (
          <ToastContent
            browserLocale={browserLocale}
            currLocale={currLocale}
            supportedLang={supportedLang}
            onAccept={() => onAccept(closeToast)}
            onCancel={() => onCancel(closeToast)}
            isPending={isPending}
          />
        ),
        TOAST_OPTIONS,
      );
    }, [
      browserLocale,
      currLocale,
      supportedLang,
      isPending,
      onAccept,
      onCancel,
    ]);

    useEffect(() => {
      showToast();
    }, [showToast]);

    return null;
  },
);

OfferChangeLang.displayName = "OfferChangeLang";
