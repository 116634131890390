import { ToastOptions } from "react-toastify";

export const TOAST_OPTIONS: ToastOptions = {
  toastId: "offer-change-lang",
  closeButton: false,
  autoClose: false,
  position: "bottom-right",
  className:
    "shadow-md! shadow-black! min-[480px]:w-90! pointer-events-auto border-1 border-primary",
  draggable: false,
  icon: false,
};

export const WEEK_IN_SECONDS = 60 * 60 * 24 * 7; // 7 дней
export const YEAR_IN_SECONDS = 365 * 24 * 60 * 60; // 1 год

export const COOKIE_OPTIONS = {
  hideDetect: {
    maxAge: WEEK_IN_SECONDS,
  },
  locale: {
    maxAge: YEAR_IN_SECONDS,
    sameSite: "lax" as const,
  },
};
