"use client";

import { PATH_HOME } from ".constants/links";
import Image from "next/image";
import Link from "next/link";

import { useCompany } from "@/components/hooks/useCompany";
import { cn } from "@/utils/cn";

type HeaderLogoProps = {
  promo?: boolean;
  className?: string;
};

export const HeaderLogo = ({ promo, className }: HeaderLogoProps) => {
  const { company, companyLogo } = useCompany();
  return (
    <Link
      href={PATH_HOME}
      className={cn(
        "flex items-center justify-center gap-2 text-base font-bold",
        "lg:text-2xl",
        className,
      )}
    >
      <Image
        src={companyLogo}
        alt={`${company} logo`}
        width={40}
        height={40}
        title={company}
        className="size-8 lg:size-10"
      />
      {promo ? (
        <div className="flex flex-col items-end text-sm">
          <span>{company}</span>
          <span>
            Sweetie<span className="text-primary">Fox</span>
          </span>
        </div>
      ) : (
        <span>{company}</span>
      )}
    </Link>
  );
};
