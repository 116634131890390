import { Button } from "@cloai/uikit";
import { useTranslations } from "next-intl";
import { memo } from "react";

import { LanguageCode, useFlag } from "@/components/hooks/useLanguage";

import type { ToastContentProps } from "./types";

export const ToastContent = memo(
  ({
    supportedLang,
    currLocale,
    browserLocale,
    onAccept,
    onCancel,
    isPending,
  }: ToastContentProps) => {
    const t = useTranslations("OfferChangeLang");

    const flag = useFlag();

    return (
      <div className="flex flex-col gap-2">
        <p>
          {t.rich("toastTitle", {
            span: () => <span className="text-primary">{supportedLang}</span>,
          })}
        </p>
        <div className="mt-2 flex w-full items-center justify-end gap-2">
          <Button
            iconLeft={flag(currLocale as LanguageCode)}
            text={t("declineBtnText")}
            size="small"
            color="outline"
            wide
            onClick={onCancel}
            loading={isPending}
          />
          <Button
            iconLeft={flag(browserLocale as LanguageCode)}
            text={t("acceptBtnText")}
            size="small"
            wide
            onClick={onAccept}
            loading={isPending}
          />
        </div>
      </div>
    );
  },
);

ToastContent.displayName = "ToastContent";
